import { envBool } from '@nsf/utils/EnvUtils.js'

export default {
  features: {

    // TODO: port configuration from my-account-simplified
    addressFromLocalStorageEnabled: false,
    addressValidationInRegistrationEnabled: true,

    // TODO():, get rid of theese agreements
    agreementsEblokEnabled: false,
    agreementsEmailSmsEnabled: false,
    agreementsGeneralProgramTermsTextEnabled: false,
    agreementsPersonalDataEnabled: false,
    agreementsSmsChannel: ['EMAIL', 'SMS'],
    agreementsStaffinoEnabled: false,
    agreementsThirdPartyMarketingEnabled: false,

    babyClubEnabled: true,
    birthDateEnabled: true,
    birthDateOverEnabled: 16,
    birthDateRequired: true,
    birthDateValidateOnSubmit: false,

    companyDataPrefillEnabled: true,
    companyItInvoicingEnabled: false,

    competitionCouponLimit: 20,
    competitionImagePath: '/images/competition/cz-competition.png',
    competitionNoticeEnabled: false,

    correspondenceEmailEnabled: false,

    customerSupportWhatsappChannelEnabled: false,
    degreesEnabled: true,
    discountEnabled: true,
    downloadInvoiceEnabled: false,

    /*eslint-disable */
    favoriteFilterCategoriesIds: [1271, 1293, 1305, 1445, 1507, 1679, 1915, 2205, 2377, 2859],

    loyaltyCardBackImagePath: '',
    loyaltyCardCodeLengthCheckEnabled: false,
    loyaltyCardImagePath: '/images/loyalty-card/cz-loyalty-card-with-comments.png',
    loyaltyCardMyProfileImagePath: '/images/loyalty-card/loyalty-card-my-profile-red.svg',
    loyaltyCardV2: true,

    allowEmailChange: false,
    phoneNumberRequired: true,
    priceEnabled: false,
    priceSummaryEnabled: true,
    registrationAddressEnabled: true,
    removeUserEnabled: true,
    returnIbanFieldEnabled: false,

    sexEnabled: true,
    sexRequiredEnabled: true,
    sexThirdOptionEnabled: false,
    surveyEnabled: false,
    typingInLoginEmailEnabled: false,
    unitPriceEnabled: false,
    ordersFilterFromEshopEnabled: true,

    emailRestrictedDomainEnabled: false,

    userFillableDetails: {
      allergies: false,
      breastFeeding: false,
      height: false,
      pregnant: false,
      treatments: false,
      weight: false,
    },
  },
}
