import { field } from '@nsf/core/GraphQL.js'

export default field('applied_shipping_restrictions', [
  field('code'),
  field('title'),
  field('reason'),
  field('shipping_method', [
    field('method_code'),
    field('carrier_title'),
    field('icon'),
    field('courier_id'),
    field('pickup_place_types'),
    field('method_title'),
  ]),
])
